/* You can add global styles to this file, and also import other style files */

$input-placeholder-color: #dddddd;

@import 'node_modules/@tabler/core/dist/css/tabler.css';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
//@import 'node_modules/ngx-toastr/toastr-bs4-alert';

//@import "node_modules/animate.css/animate";
@import "node_modules/@tabler/icons-webfont/dist/tabler-icons";

.app-options-dropdown .dropdown-toggle::after {
  display: none;
}

.modal-open .page {
  filter: blur(3px);
}

.modal-content .text-light {
  color: $gray-400 !important;
}

// fix for tabler 1.0.0.alpha6. Already fixed with commit 9e12536
.navbar-collapse .navbar {
  flex-grow: 1;
}

.nav-link-icon {
  height: inherit !important;
}
